import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBhmrW-YW2P0TJxLK4e-isw87kha2laFfU",
  authDomain: "kamala4blank.firebaseapp.com",
  projectId: "kamala4blank",
  storageBucket: "kamala4blank.appspot.com",
  messagingSenderId: "953523531177",
  appId: "1:953523531177:web:955458a9b3b274228e28b8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app, 'us-central1');
const db = getFirestore(app);

// Cloud Functions
export const getKamalaProducts = httpsCallable(functions, 'getKamalaProducts');
export const createCheckout = httpsCallable(functions, 'createCheckout');
export const addLineItems = httpsCallable(functions, 'addLineItems');
export const updateLineItems = httpsCallable(functions, 'updateLineItems');
export const removeLineItems = httpsCallable(functions, 'removeLineItems');
export const testShopifyAPI = httpsCallable(functions, 'testShopifyAPI');

// Export Firestore and Analytics
export { analytics, logEvent, db };
