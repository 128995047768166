import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { getKamalaProducts } from './firebase';
import { analytics, logEvent } from './firebase';
import Header from './components/Header';
import Cart from './components/Cart';
import ProductCard from './components/ProductCard';
import LandingPage from './components/LandingPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Footer from './components/Footer';
import Popup from './components/Popup';
import Checkout from './components/Checkout';

function App() {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [popupMessage, setPopupMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    async function fetchProducts() {
      try {
        setLoading(true);
        const result = await getKamalaProducts();
        if (result && result.data && result.data.products && Array.isArray(result.data.products)) {
          setProducts(result.data.products);
          logEvent(analytics, 'view_product_list', {
            item_list_id: 'kamala_products',
            item_list_name: 'Kamala Products'
          });
        } else {
          throw new Error('Invalid data format received from server');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load Kamala products. Please try again later.');
        setLoading(false);
      }
    }
    fetchProducts();
  }, []);

  const addToCart = (product, variant, quantity, customFields) => {
    setCart(prevCart => {
      const existingItem = prevCart.find(item => 
        item.variant.id === variant.id && 
        item.customFields.customText === customFields.customText
      );
      if (existingItem) {
        return prevCart.map(item => 
          item.variant.id === variant.id && item.customFields.customText === customFields.customText
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      } else {
        return [...prevCart, { product, variant, quantity, customFields }];
      }
    });
  };

  const updateCartItem = (variantId, newQuantity, customText) => {
    setCart(prevCart => prevCart.map(item => 
      item.variant.id === variantId && item.customFields.customText === customText
        ? { ...item, quantity: newQuantity }
        : item
    ));
  };

  const removeFromCart = (variantId, customText) => {
    setCart(prevCart => prevCart.filter(item => 
      !(item.variant.id === variantId && item.customFields.customText === customText)
    ));
  };

  const cartItemsCount = cart.reduce((total, item) => total + item.quantity, 0);

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem('cart');
  };

  return (
    <Router>
      <div className="min-h-screen bg-white font-mono">
        {popupMessage && (
          <Popup message={popupMessage} onClose={() => setPopupMessage(null)} />
        )}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/*"
            element={
              <>
                <Header cartItemsCount={cartItemsCount} />
                <Routes>
                  <Route path="/shop" element={
                    <main className="container mx-auto p-4">
                      <h2 className="text-4xl font-bold mb-8 text-center uppercase">Kamala Harris 4 ______.</h2>
                      {loading && (
                        <div className="flex justify-center items-center">
                          <div className="animate-pulse text-6xl font-bold">LOADING...</div>
                        </div>
                      )}
                      {error && <p className="text-center text-red-600 text-2xl font-bold">{error}</p>}
                      {!loading && !error && (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                          {products.map(product => (
                            <ProductCard 
                              key={product.id} 
                              product={product} 
                              addToCart={addToCart} 
                              setPopupMessage={setPopupMessage} 
                            />
                          ))}
                        </div>
                      )}
                    </main>
                  } />
                  <Route path="/cart" element={
                    <Cart 
                      cart={cart} 
                      removeFromCart={removeFromCart} 
                      updateCartItem={updateCartItem}
                      setPopupMessage={setPopupMessage}
                      clearCart={clearCart}
                    />
                  } />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/terms" element={<TermsOfService />} />
                  <Route path="/checkout" element={<Checkout />} />
                </Routes>
              </>
            }
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
