import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const footerClosed = localStorage.getItem('footerClosed');
    if (footerClosed) {
      setIsVisible(false);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('footerClosed', 'true');
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  if (!isVisible) return null;

  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-black text-white p-2 sm:p-4 font-mono border-t-2 sm:border-t-4 border-white">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center">
        <div className="flex flex-col sm:flex-row mb-2 sm:mb-0">
          <button 
            onClick={() => handleNavigate('/privacy')} 
            className="mb-2 sm:mb-0 sm:mr-4 hover:underline bg-transparent border-2 border-white text-white cursor-pointer px-2 py-1 text-xs sm:text-sm uppercase font-bold"
          >
            Privacy Policy
          </button>
          <button 
            onClick={() => handleNavigate('/terms')} 
            className="hover:underline bg-transparent border-2 border-white text-white cursor-pointer px-2 py-1 text-xs sm:text-sm uppercase font-bold"
          >
            Terms of Service
          </button>
        </div>
        <button 
          onClick={handleClose} 
          className="mt-2 sm:mt-0 text-white hover:text-gray-300 bg-transparent border-2 border-white cursor-pointer px-2 py-1 text-xs sm:text-sm uppercase font-bold"
        >
          Close
        </button>
      </div>
    </footer>
  );
}

export default Footer;
