import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import kamalaImage from '../imgs/kamala4blank.png';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

function LandingPage() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const navigate = useNavigate();

  useEffect(() => {
    logEvent(analytics, 'view_landing_page');
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date('2024-11-05') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  const handleEnter = () => {
    logEvent(analytics, 'enter_shop', {
      source: 'landing_page'
    });
    navigate('/shop');
  };

  return (
    <div className="min-h-screen bg-white text-black font-mono flex items-center justify-center">
      <div className="container mx-auto px-4 py-8 flex flex-col items-center justify-center">
        <div className="mb-8 w-full max-w-md md:max-w-lg lg:max-w-xl">
          <img src={kamalaImage} alt="Kamala Harris" className="w-full h-auto object-cover" />
        </div>

        <div className="text-center mb-8">
          <h1 className="text-4xl md:text-5xl font-bold mb-4 uppercase">Kamala 4 Blank</h1>
          <p className="text-xl md:text-2xl mb-4 uppercase">Time left until Election Day:</p>
          <div className="flex flex-nowrap justify-center gap-1 text-xs sm:text-sm md:text-base lg:text-lg">
            <div className="bg-gray-100 p-1 sm:p-2 rounded flex-shrink-0 w-[70px] sm:w-[80px]"><span className="font-bold">{timeLeft.days}</span> days</div>
            <div className="bg-gray-100 p-1 sm:p-2 rounded flex-shrink-0 w-[60px] sm:w-[70px]"><span className="font-bold">{timeLeft.hours}</span> hrs</div>
            <div className="bg-gray-100 p-1 sm:p-2 rounded flex-shrink-0 w-[60px] sm:w-[70px]"><span className="font-bold">{timeLeft.minutes}</span> min</div>
            <div className="bg-gray-100 p-1 sm:p-2 rounded flex-shrink-0 w-[60px] sm:w-[70px]"><span className="font-bold">{timeLeft.seconds}</span> sec</div>
          </div>
        </div>

        <button 
          onClick={handleEnter}
          className="bg-black text-white font-bold py-3 px-6 text-xl md:text-2xl uppercase hover:bg-gray-800 transition-colors rounded"
        >
          Enter
        </button>
      </div>
    </div>
  );
}

export default LandingPage;
