import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function Checkout() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkoutUrl = location.state?.checkoutUrl;
    if (checkoutUrl) {
      // Redirect to the Shopify checkout
      window.location.href = checkoutUrl;
    } else {
      // If no checkout URL is provided, redirect back to the cart
      navigate('/cart');
    }
  }, [location.state, navigate]);

  return (
    <div className="container mx-auto p-4 font-mono">
      <h2 className="text-4xl font-bold mb-8 uppercase">Redirecting to Checkout...</h2>
      <p>Please wait while we redirect you to the secure checkout page.</p>
    </div>
  );
}

export default Checkout;
