import React from 'react';
import { Link } from 'react-router-dom';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';


function TermsOfService() {
  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: 'Terms of Service',
      page_path: '/terms'
    });
  }, []);



  return (
    <div className="container mx-auto px-4 py-8 font-mono">
      <h1 className="text-5xl font-bold mb-8 uppercase">Terms of Service</h1>
      <p className="mb-8">Last updated: 10/01/2024</p>

      <div className="bg-blue-600 text-white p-4 mb-8 text-xl font-bold">
        IMPORTANT NOTICE: This website is not affiliated with, endorsed by, or connected to Vice President Kamala Harris, any Presidential Campaign for Kamala Harris, or any political party. This is an independent initiative.
      </div>

      <h2 className="text-3xl font-bold mt-8 mb-4 uppercase">1. Acceptance of Terms</h2>
      <p className="mb-4">By accessing and using this website, you accept and agree to be bound by the terms and provisions of this agreement.</p>

      <h2 className="text-3xl font-bold mt-8 mb-4 uppercase">2. Use License</h2>
      <p className="mb-4">Permission is granted to temporarily download one copy of the materials (information or software) on this website for personal, non-commercial transitory viewing only.</p>

      <h2 className="text-3xl font-bold mt-8 mb-4 uppercase">3. Disclaimer</h2>
      <p className="mb-4">The materials on this website are provided on an 'as is' basis. We make no warranties, expressed or implied, and hereby disclaim and negate all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>

      <h2 className="text-3xl font-bold mt-8 mb-4 uppercase">4. Limitations</h2>
      <p className="mb-4">In no event shall we or our suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on this website.</p>

      <h2 className="text-3xl font-bold mt-8 mb-4 uppercase">5. Revisions and Errata</h2>
      <p className="mb-4">The materials appearing on this website could include technical, typographical, or photographic errors. We do not warrant that any of the materials on this website are accurate, complete or current.</p>

      <Link to="/" className="mt-8 inline-block bg-black text-white font-bold py-4 px-8 text-xl uppercase hover:bg-gray-800 transition-colors">Back to Home</Link>
    </div>
  );
}

export default TermsOfService;
