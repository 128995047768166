import React from 'react';
import { Link } from 'react-router-dom';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';

function PrivacyPolicy() {
  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: 'Privacy Policy',
      page_path: '/privacy'
    });
  }, []);

  return (
    <div className="container mx-auto px-4 py-8 font-mono">
      <h1 className="text-5xl font-bold mb-8 uppercase">Privacy Policy</h1>
      <p className="mb-8">Last updated: 10/01/2024</p>

      <h2 className="text-3xl font-bold mt-8 mb-4 uppercase">1. Information We Collect</h2>
      <p className="mb-4">We collect information you provide directly to us, such as when you join our waitlist or fill out a form. This may include your email address and any other information you choose to provide.</p>

      <h2 className="text-3xl font-bold mt-8 mb-4 uppercase">2. How We Use Your Information</h2>
      <p className="mb-4">We use the information we collect to operate, maintain, and provide to you the features and functionality of our service, including to send you updates and information about our initiative.</p>

      <h2 className="text-3xl font-bold mt-8 mb-4 uppercase">3. Sharing of Your Information</h2>
      <p className="mb-4">We do not share, sell, rent, or trade your personal information with third parties for their commercial purposes.</p>

      <h2 className="text-3xl font-bold mt-8 mb-4 uppercase">4. Data Security</h2>
      <p className="mb-4">We use reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.</p>

      <h2 className="text-3xl font-bold mt-8 mb-4 uppercase">5. Changes to This Privacy Policy</h2>
      <p className="mb-4">We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.</p>

      <h2 className="text-3xl font-bold mt-8 mb-4 uppercase">6. Contact Us</h2>
      <p className="mb-4">If you have any questions about this Privacy Policy, please contact us at help@kamala4blank.com</p>

      <Link to="/" className="mt-8 inline-block bg-black text-white font-bold py-4 px-8 text-xl uppercase hover:bg-gray-800 transition-colors">Back to Home</Link>
    </div>
  );
}

export default PrivacyPolicy;
