import React, { useState } from 'react';
import { logEvent } from '../firebase';

function ProductCard({ product, addToCart, setPopupMessage }) {
  const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);
  const [quantity, setQuantity] = useState(1);
  const [customText, setCustomText] = useState('');

  const formatPrice = (price) => {
    if (price && price.amount) {
      return `$${parseInt(price.amount, 10)}`;
    }
    return '$0';
  };

  const handleVariantSelect = (variant) => {
    setSelectedVariant(variant);
    setQuantity(1);
  };

  const handleAddToCart = () => {
    addToCart(product, selectedVariant, quantity, { customText });
    setPopupMessage(`Added ${quantity} ${product.title} to cart.`);
    setCustomText('');
    logEvent('add_to_cart', {
      currency: 'USD',
      value: parseFloat(selectedVariant.price.amount) * quantity,
      items: [{
        item_id: product.id,
        item_name: product.title,
        item_variant: selectedVariant.title,
        price: parseFloat(selectedVariant.price.amount),
        quantity: quantity
      }]
    });
  };

  return (
    <div className="border-4 border-black p-4">
      <img 
        src={product.images[0]?.src || 'placeholder-image-url.jpg'} 
        alt={product.title} 
        className="w-full h-auto mb-4"
      />
      <h3 className="text-2xl font-bold mb-2 uppercase">{product.title}</h3>
      <p className="mb-4">{product.description}</p>
      <p className="text-2xl font-bold mb-2">
        {formatPrice(selectedVariant.price)}
      </p>
      <div className="flex flex-wrap gap-2 mb-4">
        {product.variants.map(variant => (
          <button 
            key={variant.id} 
            className={`p-2 border-2 border-black ${
              selectedVariant.id === variant.id
                ? 'bg-black text-white'
                : 'bg-white text-black hover:bg-gray-200'
            }`}
            onClick={() => handleVariantSelect(variant)}
          >
            {variant.title}
          </button>
        ))}
      </div>
      <div className="flex items-center mb-4">
        <span className="mr-2 uppercase">Quantity:</span>
        <button 
          onClick={() => setQuantity(Math.max(1, quantity - 1))}
          className="border-2 border-black px-2 py-1 mr-2 hover:bg-gray-200"
          disabled={quantity <= 1}
        >
          -
        </button>
        <span className="mx-2">{quantity}</span>
        <button 
          onClick={() => setQuantity(quantity + 1)}
          className="border-2 border-black px-2 py-1 ml-2 hover:bg-gray-200"
        >
          +
        </button>
      </div>
      <button 
        className="w-full py-2 bg-black text-white text-xl font-bold hover:bg-gray-800 transition-colors duration-300 uppercase"
        onClick={handleAddToCart}
      >
        Add to Cart
      </button>
    </div>
  );
}

export default ProductCard;
