import React, { useEffect } from 'react';

function Popup({ message, onClose }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
      <div className="bg-white text-black p-4 max-w-md w-full text-center font-mono border-8 border-black">
        <p className="mb-2 text-xl uppercase font-bold">{message}</p>
        <button 
          onClick={onClose}
          className="mt-4 bg-black text-white font-bold py-2 px-4 uppercase border-4 border-black hover:bg-white hover:text-black transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default Popup;
