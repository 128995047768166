import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createCheckout, addLineItems } from '../firebase';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { FaArrowLeft } from 'react-icons/fa';

function Cart({ cart, removeFromCart, updateCartItem, setPopupMessage, clearCart }) {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const checkoutSessionId = urlParams.get('session_id');
    
    if (checkoutSessionId) {
      handleCheckoutCompletion(checkoutSessionId);
    }
  }, []);

  const handleCheckoutCompletion = async (sessionId) => {
    try {
      // Verify the checkout session with your backend
      const response = await fetch(`/api/verify-checkout?session_id=${sessionId}`);
      const data = await response.json();
      
      if (data.success) {
        clearCart();
        setPopupMessage('Thank you for your purchase!');
        navigate('/shop');
      } else {
        setPopupMessage('There was an issue with your checkout. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying checkout:', error);
      setPopupMessage('There was an error processing your checkout. Please contact support.');
    }
  };

  const formatPrice = (price) => {
    if (price && price.amount) {
      return `$${parseInt(price.amount, 10)}`;
    }
    return '$0';
  };

  const handleQuantityChange = (item, newQuantity) => {
    if (newQuantity <= 0) {
      removeFromCart(item.variant.id, item.customFields.customText);
    } else {
      updateCartItem(item.variant.id, newQuantity, item.customFields.customText);
    }
  };

  const total = cart.reduce((sum, item) => {
    const price = item.variant.price && item.variant.price.amount 
      ? parseFloat(item.variant.price.amount) 
      : 0;
    return sum + price * item.quantity;
  }, 0);

  const handleProceedToCheckout = async () => {
    try {
      const checkoutResult = await createCheckout();
      const checkout = checkoutResult.data.checkout;
      const lineItems = cart.map(item => ({
        variantId: item.variant.id,
        quantity: item.quantity,
        customAttributes: [
          { key: 'Custom Text', value: item.customFields.customText }
        ]
      }));
      const updatedCheckoutResult = await addLineItems({ checkoutId: checkout.id, lineItems });
      const updatedCheckout = updatedCheckoutResult.data.checkout;
      
      // Use the primary checkout domain
      const checkoutUrl = new URL(updatedCheckout.webUrl);
      checkoutUrl.hostname = 'checkout.kamala4blank.com';
      
      logEvent(analytics, 'begin_checkout', {
        currency: 'USD',
        value: total,
        items: cart.map(item => ({
          item_id: item.product.id,
          item_name: item.product.title,
          item_variant: item.variant.title,
          price: parseFloat(item.variant.price.amount),
          quantity: item.quantity
        }))
      });

      // Redirect to the checkout URL
      window.location.href = checkoutUrl.toString();
    } catch (error) {
      console.error('Error during checkout:', error);
      setPopupMessage('Failed to create checkout. Please try again.');
    }
  };

  return (
    <div className="container mx-auto p-4 font-mono">
      <div className="flex items-center mb-8">
        <Link to="/shop" className="mr-4">
          <FaArrowLeft className="text-3xl hover:text-gray-700" />
        </Link>
        <h2 className="text-4xl font-bold uppercase">Your Cart</h2>
      </div>
      {cart.length === 0 ? (
        <p className="text-2xl mb-4">Your cart is empty.</p>
      ) : (
        <>
          {cart.map((item) => (
            <div key={`${item.variant.id}-${item.customFields.customText}`} className="border-4 border-black p-4 mb-4">
              <h3 className="text-2xl font-bold uppercase mb-2">{item.product.title}</h3>
              <div className="flex flex-row justify-between items-start">
                <div className="flex flex-col">
                  <p className="mb-2">Variant: {item.variant.title}</p>
                  <p className="mb-2">Custom Text: {item.customFields.customText}</p>
                  <p className="mb-2">Price: {formatPrice(item.variant.price)}</p>
                  <div className="flex items-center mb-2">
                    <span className="mr-2">Quantity:</span>
                    <button 
                      onClick={() => handleQuantityChange(item, item.quantity - 1)}
                      className="border-2 border-black px-2 py-1 mr-2 hover:bg-gray-200"
                    >
                      -
                    </button>
                    <span className="mx-2">{item.quantity}</span>
                    <button 
                      onClick={() => handleQuantityChange(item, item.quantity + 1)}
                      className="border-2 border-black px-2 py-1 ml-2 hover:bg-gray-200"
                    >
                      +
                    </button>
                  </div>
                  <button 
                    onClick={() => removeFromCart(item.variant.id, item.customFields.customText)}
                    className="bg-black text-white px-4 py-2 uppercase hover:bg-gray-800 self-start mt-2"
                  >
                    Remove
                  </button>
                </div>
                <div className="ml-4">
                  <img 
                    src={item.product.images[0]?.src || 'placeholder-image-url.jpg'} 
                    alt={item.product.title} 
                    className="w-full max-w-[150px] h-auto"
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="mt-8">
            <p className="text-2xl font-bold mb-4">Total: {formatPrice({amount: total.toFixed(2)})}</p>
            <button 
              onClick={handleProceedToCheckout}
              className="w-full py-2 bg-black text-white text-xl font-bold hover:bg-gray-800 transition-colors duration-300 uppercase"
            >
              Proceed to Checkout
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default Cart;
