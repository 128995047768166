import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';

function Header({ cartItemsCount }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className="bg-black text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/shop" className="text-3xl font-bold uppercase">Kamala 2024</Link>
        
        {/* Web Nav */}
        <nav className="hidden md:block">
          <ul className="flex space-x-8">
            <li>
              <Link to="/cart" className="text-xl uppercase hover:underline">
                Cart ({cartItemsCount})
              </Link>
            </li>
          </ul>
        </nav>

        {/* Mobile Nav */}
        <div className="md:hidden">
          <Link to="/cart" className="text-2xl">
            <FaShoppingCart className="inline-block" />
            <span className="ml-1">{cartItemsCount}</span>
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
